/* for extra features should include this */

.vimeo,
.youtube {
    position: relative;
    cursor: pointer;
    display: table;
    width: 100%;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: black;
    overflow: hidden;
}
/* youtube always use 16:9 aspect ratio video */
.youtube {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
}
.vimeo img {
    width: 100%;
    object-fit: contain;
    z-index: 0;
}
.youtube img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}
.vimeo iframe,
.youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    z-index: 1;
}
.vimeo .icon,
.youtube .icon {
    position: absolute;
    height: auto;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    opacity: 0.3;
    -webkit-transition: opacity 0.2s; /* Safari */
    transition: opacity 0.2s;
    z-index: 0;
}
.vimeo:hover .icon,
.youtube:hover .icon {
    opacity: 0.6;
    -webkit-transition: opacity 0.2s; /* Safari */
    transition: opacity 0.2s;
}

.slideshare .inner,
.speakerdeck .inner {
    position: relative;
    width: 100%;
}
.slideshare .inner iframe,
.speakerdeck .inner iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.MJX_Assistive_MathML {
    display: none;
}

.ui-infobar {
    position: relative;
    z-index: 2;
    max-width: 758px;
    margin-top: 25px;
    margin-bottom: -25px;
    color: #777;
}

.toc .invisable-node {
  list-style-type: none;
}

.ui-toc {
    position: fixed;
    bottom: 20px;
    z-index: 10000;
}

.ui-toc-label {
    opacity: 0.3;
    background-color: #ccc;
    border: none;
    -webkit-transition: opacity 0.2s; /* Safari */
    transition: opacity 0.2s;
}

.ui-toc .open .ui-toc-label {
    opacity: 1;
    color: white;
    -webkit-transition: opacity 0.2s; /* Safari */
    transition: opacity 0.2s;
}

.ui-toc-label:focus {
    opacity: 0.3;
    background-color: #ccc;
    color: black;
}

.ui-toc-label:hover {
    opacity: 1;
    background-color: #ccc;
    -webkit-transition: opacity 0.2s; /* Safari */
    transition: opacity 0.2s;
}

.ui-toc-dropdown {
    margin-top: 23px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 45vw;
    width: 25vw;
    max-height: 70vh;
    overflow: auto;
    text-align: inherit;
}

.ui-toc-dropdown > .toc {
    max-height: calc(70vh - 100px);
    overflow: auto;
}

.ui-toc-dropdown[dir='rtl'] .nav {
    padding-right: 0;
    letter-spacing: 0.0029em;
}

.ui-toc-dropdown a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

.ui-toc-dropdown .nav>li>a {
    display: block;
    padding: 4px 20px;
    font-size: 13px;
    font-weight: 500;
    color: #767676;
}

.ui-toc-dropdown .toc.expand ul {
    display: block;
}

.ui-toc-dropdown .nav > li:first-child:nth-last-child(1) > ul {
    display: block;
}

.ui-toc-dropdown .nav>li>a:focus,.ui-toc-dropdown .nav>li>a:hover {
    padding-left: 19px;
    color: black;
    text-decoration: none;
    background-color: transparent;
    border-left: 1px solid black;
}

.night .ui-toc-dropdown .nav>li>a:focus, .night .ui-toc-dropdown .nav>li>a:hover{
    color: white;
    border-left-color: white;
}

.ui-toc-dropdown[dir='rtl'] .nav>li>a:focus,.ui-toc-dropdown[dir='rtl'] .nav>li>a:hover {
    padding-right: 19px;
    border-left: none;
    border-right: 1px solid black;
}

.ui-toc-dropdown .nav>.active:focus>a,.ui-toc-dropdown .nav>.active:hover>a,.ui-toc-dropdown .nav>.active>a {
    padding-left: 18px;
    font-weight: 700;
    color: black;
    background-color: transparent;
    border-left: 2px solid black;
}
.night .ui-toc-dropdown .nav>.active:focus>a,.night .ui-toc-dropdown .nav>.active:hover>a,.night .ui-toc-dropdown .nav>.active>a {
    color: white;
    border-left: 2px solid white;
}

.ui-toc-dropdown[dir='rtl'] .nav>.active:focus>a,.ui-toc-dropdown[dir='rtl'] .nav>.active:hover>a,.ui-toc-dropdown[dir='rtl'] .nav>.active>a {
    padding-right: 18px;
    border-left: none;
    border-right: 2px solid black;
}

.ui-toc-dropdown .nav .nav {
    display: none;
    padding-bottom: 10px;
}

.ui-toc-dropdown .nav>.active>ul {
    display: block;
}

.ui-toc-dropdown .nav .nav>li>a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 400;
}

.night .ui-toc-dropdown .nav > li > a{
    color: #aaa;
}

.ui-toc-dropdown[dir='rtl'] .nav .nav>li>a {
    padding-right: 30px;
}

.ui-toc-dropdown .nav .nav>li>ul>li>a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 40px;
    font-size: 12px;
    font-weight: 400;
}

.ui-toc-dropdown[dir='rtl'] .nav .nav>li>ul>li>a {
    padding-right: 40px;
}

.ui-toc-dropdown .nav .nav>li>a:focus,.ui-toc-dropdown .nav .nav>li>a:hover {
    padding-left: 29px;
}

.ui-toc-dropdown[dir='rtl'] .nav .nav>li>a:focus,.ui-toc-dropdown[dir='rtl'] .nav .nav>li>a:hover {
    padding-right: 29px;
}

.ui-toc-dropdown .nav .nav>li>ul>li>a:focus,.ui-toc-dropdown .nav .nav>li>ul>li>a:hover {
    padding-left: 39px;
}

.ui-toc-dropdown[dir='rtl'] .nav .nav>li>ul>li>a:focus,.ui-toc-dropdown[dir='rtl'] .nav .nav>li>ul>li>a:hover {
    padding-right: 39px;
}

.ui-toc-dropdown .nav .nav>.active:focus>a,.ui-toc-dropdown .nav .nav>.active:hover>a,.ui-toc-dropdown .nav .nav>.active>a {
    padding-left: 28px;
    font-weight: 500;
}

.ui-toc-dropdown[dir='rtl'] .nav .nav>.active:focus>a,.ui-toc-dropdown[dir='rtl'] .nav .nav>.active:hover>a,.ui-toc-dropdown[dir='rtl'] .nav .nav>.active>a {
    padding-right: 28px;
}

.ui-toc-dropdown .nav .nav>.active>.nav>.active:focus>a,.ui-toc-dropdown .nav .nav>.active>.nav>.active:hover>a,.ui-toc-dropdown .nav .nav>.active>.nav>.active>a {
    padding-left: 38px;
    font-weight: 500;
}

.ui-toc-dropdown[dir='rtl'] .nav .nav>.active>.nav>.active:focus>a,.ui-toc-dropdown[dir='rtl'] .nav .nav>.active>.nav>.active:hover>a,.ui-toc-dropdown[dir='rtl'] .nav .nav>.active>.nav>.active>a {
    padding-right: 38px;
}

/* support japanese font */
.markdown-body[lang^="ja"] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Osaka, Meiryo, "メイリオ", "MS Gothic", "ＭＳ ゴシック", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.ui-toc-dropdown[lang^="ja"] {
    font-family: "Source Sans Pro", Helvetica, Arial, "Meiryo UI", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;
}

/* support zh-tw font */
.markdown-body[lang="zh-tw"] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, "PingFang TC", "Microsoft JhengHei", "微軟正黑", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.ui-toc-dropdown[lang="zh-tw"] {
    font-family: "Source Sans Pro", Helvetica, Arial, "Microsoft JhengHei UI", "微軟正黑UI", sans-serif;
}

/* support zh-cn font */
.markdown-body[lang="zh-cn"] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Microsoft YaHei", "微软雅黑", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.ui-toc-dropdown[lang="zh-cn"] {
    font-family: "Source Sans Pro", Helvetica, Arial, "Microsoft YaHei UI", "微软雅黑UI", sans-serif;
}

.ui-affix-toc {
    position: fixed;
    top: 0;
    max-width: 15vw;
    max-height: 70vh;
    overflow: auto;
}

.expand-toggle, .back-to-top, .go-to-bottom {
    display: block;
    padding: 4px 10px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #999;
}

.expand-toggle:hover, .expand-toggle:focus, .back-to-top:hover, .back-to-top:focus, .go-to-bottom:hover, .go-to-bottom:focus {
    color: #563d7c;
    text-decoration: none;
}

.back-to-top, .go-to-bottom {
    margin-top: 0;
}

.ui-user-icon {
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 3px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.ui-user-icon.small {
	width: 18px;
	height: 18px;
	display: inline-block;
	vertical-align: middle;
	margin: 0 0 0.2em 0;
}

small span {
    line-height: 22px;
}

small .dropdown {
    display: inline-block;
}

small .dropdown a:focus, small .dropdown a:hover {
    text-decoration: none;
}

.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.night .navbar{
    background: #333;
    border-bottom-color: #333;
    color: #eee;
}

.night .navbar a{
    color: #eee;
}


/* Prevent linked heading from being hidden underneath navbar.
 * Example: http://localhost:3000/features#Editor-Modes would open and
 * hide the headline "Editor Modes" underneath the navbar without this CSS rule.
 */
.markdown-body h1[id]:before,
.markdown-body h2[id]:before,
.markdown-body h3[id]:before,
.markdown-body h4[id]:before,
.markdown-body h5[id]:before,
.markdown-body h6[id]:before {
  display: block;
  content: " ";
  margin-top: -55px;
  height: 55px;
  visibility: hidden;
}

@media print {
    div, table, img, pre, blockquote {
        page-break-inside: avoid !important;
    }
    a[href]:after {
        font-size: 12px !important;
    }
}
