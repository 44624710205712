html, body {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    max-width: inherit;
    min-width: 200px;
    /*margin-right: 15px;*/
    padding-top: 51px;
    /*overflow: hidden;*/
}

.night a,
.night .open-files-container li.selected a {
    color: #5EB7E0;
}

body.night{
    background: #333 !important;
}

body.night .no-night {
  display: none;
}

body:not(.night) .night {
  display: none;
}

.h-100 {
  height: 100%;
}
.header-brand {
  padding: 6px;
}

.CodeMirror {
    font-family: "Source Code Pro", Consolas, monaco, monospace;
    letter-spacing: 0.025em;
    line-height: 1.25;
    font-size: 18px;
    height: auto !important;
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: touch;
}
/* support japanese font */
.CodeMirror[lang^="ja"] {
    font-family: "Source Code Pro", Consolas, monaco, Meiryo, "ＭＳ ゴシック", "MS Gothic", monospace;
}
/* support zh-tw font */
.CodeMirror[lang="zh-tw"] {
    font-family: "Source Code Pro", Consolas, monaco, "Microsoft JhengHei", "微軟正黑", monospace;
}
/* support zh-cn font */
.CodeMirror[lang="zh-cn"] {
    font-family: "Source Code Pro", Consolas, monaco, "Microsoft YaHei", "微软雅黑", monospace;
}
.CodeMirror-placeholder {
    color: #777 !important;
}
.CodeMirror-scroll {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}
.CodeMirror-code {
    margin-bottom: 36px;
}
.CodeMirror-gutter-elt {
    text-align: center;
}
.CodeMirror-linenumber {
/*    opacity: 0.5;*/
    min-width: 1.5em;
    text-align: right;
}
.CodeMirror-gutter.authorship-gutters {
    width: 8px;
}
.CodeMirror-matchingtag {
    background: rgba(255, 255, 255, .1);
    line-height: 1em;
}
.CodeMirror-foldmarker {
    color: #d0d0d0;
    text-shadow: none;
    font-family: Arial;
    font-size: 1em;
    line-height: .3;
    cursor: pointer;
    margin: 2px;
    padding-bottom: 2px;
}
.CodeMirror-foldgutter {
    /*width: 1em;*/
    cursor: default;
    line-height: 100%;
}
.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
    line-height: 1em;
    cursor: pointer;
}
.CodeMirror-foldgutter-open {
    padding-top: 1px;
}
.CodeMirror-foldgutter-folded {
    padding-top: 2px;
}
.CodeMirror-foldgutter-open:after {
    content: "⌵";
    font-size: 1em;
/*    opacity: 0.5;*/
}
.CodeMirror-foldgutter-folded:after {
    content: "+";
    font-size: 1em;
    font-weight: 700;
}
.CodeMirror-foldmarker, .CodeMirror-foldgutter-folded:after {
    color: #78B2F2 !important;
}
.CodeMirror-sizer {
    /* Make sure CodeMirror doesn't hide text under the status bar
     * 26px is the height of the status bar.
     */
    margin-bottom: 26px !important;
}
.CodeMirror-insert-match {
  background: lawngreen;
  border: 1px solid limegreen;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  opacity: .5;
}
.CodeMirror-delete-match {
  background: indianred;
  border: 1px solid darkred;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  opacity: .5;
}
.ui-content {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
}

.night .ui-content{
    background-color: #333;
}

.ui-edit-area {
    height: 100%;
    /*padding-left: 15px;*/
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.ui-edit-area .ui-resizable-handle.ui-resizable-e {
    cursor: col-resize;
    width: 8px;
    right: -8px;
    background-color: white;
    box-shadow: 3px 0px 6px #e7e7e7;
}
.ui-edit-area .ui-sync-toggle {
    width: 42px;
    height: 42px;
    padding: 2px 1px 0 0;
    border-radius: 50%;
    box-shadow: 2px 0px 2px #e7e7e7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.night .ui-edit-area .ui-sync-toggle {
    box-shadow: 2px 0px 2px #353535;
}

.ui-edit-area .ui-sync-toggle:active {
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125), 2px 0px 2px #e7e7e7;
}

.night .ui-edit-area .ui-resizable-handle.ui-resizable-e{
   background: #3c3c3c;
   box-shadow: 3px 0px 6px #353535;
}

.ui-view-area {
    /*overflow-y: scroll;*/
    -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
    .ui-view-area {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.night .ui-view-area{
    background: #333;
    color: #ededed;
}


.ui-scrollable {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.ui-status * {
    font-size: 16px !important;
}
.navbar-brand {
    font-weight: bold;
}
.nav-status .ui-status * {
    font-size: 14px;
}
.nav-mobile {
    position: inherit;
    margin-top: 8px;
    margin-bottom: 8px;
}
.nav-mobile .dropdown-menu {
    left: 40%;
    right: 6px;
    top: 42px;
}
.nav-status {
    float: right !important;
    padding: 7px 8px;
}
.ui-status {
    cursor: auto !important;
    min-width: 120px;
    background-color: transparent !important;
}
.ui-status span {
    cursor: pointer;
}
.ui-short-status {
    cursor: pointer;
    min-width: 40px;
}
.ui-short-status:hover {
    text-decoration: none;
}
.ui-user-item {
    /*na*/
}
.ui-user-name {
    margin-top: 2px;
}

.ui-user-status {
    margin-top: 5px;
}
.ui-user-status-online {
    color: rgb(92,184,92);
}
.ui-user-status-idle {
    color: rgb(240,173,78);
}
.ui-user-status-offline {
    color: rgb(119,119,119);
}
.list > li > a {
    overflow: hidden;
    text-overflow: ellipsis;
}
#short-online-user-list .list .name {
    max-width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}
#online-user-list .list .name {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}
.navbar-right {
    margin-right: 0;
}
.navbar-nav > li > a {
    cursor: pointer;
}

.night .navbar-default .navbar-nav > li > a:focus,
.night .navbar-default .navbar-nav > li > a:hover,
.night .navbar-default .navbar-brand:focus,
.night .navbar-default .navbar-brand:hover{
    color: #fff;
}

.night .navbar-default .navbar-nav > .open > a,
.night .navbar-default .navbar-nav > .open > a:focus,
.night .navbar-default .navbar-nav > .open > a:hover {
    color: white;
    background: #000;

}
.dropdown-menu > li > a {
    cursor: pointer;
    text-overflow: ellipsis;
    max-width: calc(100vw - 30px);
    overflow: hidden;
}

.night .dropdown-menu > li > a {
    color: #eee;
}

.night .dropdown-menu > li > a:focus,
.night .dropdown-menu > li > a:hover {
  color: #262626
}

.night .dropdown-menu {
    background: #222;
}

.night .modal-content,
.night .panel,
.night .panel-heading {
  color: #eee;
  background-color: #333;
}

.dropdown-menu.CodeMirror-other-cursor {
    transition: none;
}
.cursortag {
    cursor: pointer;
    background: black;
    position: absolute;
    padding: 2px 7px 2px 8px;
    font-size: 12px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: inherit;
    border-radius: .25em;
    white-space: nowrap;
    transition: left 0.1s ease-in-out, top 0.1s ease-in-out;
}
.fixfixed .navbar-fixed-top {
    position: absolute !important;
}
div[contenteditable]:empty:not(:focus):before{
    content:attr(data-ph);
    color: gray;
}
.dropdown-menu.list {
    max-height: 80vh;
    overflow: auto;
}
.dropdown-menu.list.small {
    max-height: 40vh;
    overflow: auto;
}
.dropdown-menu.list::-webkit-scrollbar {
    display: none;
}
.dropdown-menu .emoji {
    margin-bottom: 0 !important;
}
.dropdown-menu.CodeMirror-other-cursor {
    max-height: 50vh;
    width: auto !important;
    overflow: auto;
    margin-bottom: 30px;
}
.CodeMirror-scrollbar-filler {
    background: inherit;
}

.night .navbar .btn-default,
.night .close {
    background-color: #333;
    border-color: #565656;
    color: #eee;
}

.night .btn.btn-default.active{
    background: #202020;
}

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.night .btn.focus,
.night .btn:focus,
.night .btn:hover,
.night .close {
    color: #fff;
    background-color: #333;
}

.info-label {
	width: 36%;
	text-align: right;
	position: relative;
    display: inline-block;
	margin-right: 6px;
}
.popover {
	width: 100%;
	font-family: inherit !important;
	line-height: 25px;
}

.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.cm-trailing-space-a:before,
.cm-trailing-space-b:before,
.cm-trailing-space-new-line:before {
  font-weight: bold;
  color: hsl(30, 100%, 50%); /* a dark orange */
  position: absolute;
}

.cm-trailing-space-a:before,
.cm-trailing-space-b:before {
  content: '·';
}

.cm-trailing-space-new-line:before {
  content: '↵';
}

.cm-matchhighlight {
/*    border-radius: 5px;*/
/*    box-shadow: 0 1px 0 0 #ccc;*/
    border-bottom: 1px solid #ccc;
}

.snippet-import-or {
    text-align: center;
    width: 100%;
}

.status-bar {
    background: #1c1c1e;
    border-top: 1px solid #343434;
    color: #ccc;
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 11px;
    line-height: 25px;
    height: 26px;
}

.status-bar .status-info {
    color: #ccc;
    left: 10px;
    position: absolute;
    white-space: nowrap;
    max-width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-bar .status-info div {
    display: inline;
}

.status-bar .status-file {
    color: #9a9a9a;
}

.status-bar .status-indicators {
    background: #1c1c1e;
    color: #ccc;
    position: absolute;
    right: 0;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.status-bar .status-indicators > div {
    float: right;
    padding: 0 10px;
    border-left: 1px solid #343434;
}

.status-bar .status-indicators .status-keymap > a,
.status-bar .status-indicators .status-theme > a,
.status-bar .status-indicators .status-spellcheck > a,
.status-bar .status-indicators .status-preferences > a {
    color: inherit;
    text-decoration: none;
}

.status-bar .status-indicators .status-theme,
.status-bar .status-indicators .status-spellcheck,
.status-bar .status-indicators .status-preferences {
    padding: 0 4.3px;
}

.status-bar .status-indicators .status-preferences .dropdown-menu > li > a {
    cursor: default;
}

.status-bar .status-indicators .status-preferences .dropdown-menu label {
    font: inherit;
    margin-bottom: 0;
    cursor: pointer;
}

.status-bar .status-indicators .status-preferences .dropdown-menu label > input[type="checkbox"] {
    vertical-align: middle;
    margin: -3px 0 0;
}

.ui-theme-toggle,
.ui-spellcheck-toggle {
    opacity: 0.2;
    cursor: pointer;
}

.ui-theme-toggle.active,
.ui-spellcheck-toggle.active {
    opacity: 1;
}

.ui-theme-toggle:hover,
.ui-spellcheck-toggle:hover {
    opacity: 0.8;
}

.status-bar .indent-type, .status-bar .indent-width-label {
    cursor: pointer;
/*    margin-right: 3px;*/
}

.status-bar .indent-width-input {
    font-size: 12px;
    font-weight: 500;
    height: 18px;
    line-height: 1;
    vertical-align: middle;
    color: #ccc;
    margin: 0;
    padding: 0 0 2px;
    position: relative;
    left: 0;
    top: -1px;
    width: 18px;
    transition: .1s linear all;
    background-color: #555;
    border: 1px solid #202020;
    color: #fff;
    box-shadow: inset 0 1px 0 rgba(0,0,0,0.06);
    border-radius: 3px;
    text-align: center;
}

.status-bar .indent-width-input:focus {
    border: 1px solid #2893ef;
}

.status-bar .indent-width-input::-webkit-inner-spin-button,
.status-bar .indent-width-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.status-bar .status-indent > * {
    display: inline-block;
}

.status-bar .status-indent > *.hidden {
    display: none;
}

.status-bar .status-overwrite:hover, .status-bar .indent-type:hover, .status-bar .indent-width-label:hover {
    text-decoration: underline;
}

.status-bar .dropdown-menu {
    background-color: #000;
    color: #fff;
    border: 1px solid rgba(255,255,255,0.09) !important;
}

.status-bar .dropdown-menu .divider {
    background-color: #343434;
}

.status-bar .dropdown-menu > li > a {
    color: #ccc;
}

.status-bar .dropdown-menu > li > a:focus, .status-bar .dropdown-menu > li > a:hover {
    background-color: #212121;
}

@media print {
    body {
        padding-top: 0 !important;
    }
    .CodeMirror {
        height: auto !important;
    }
}
